import React, { useEffect, useState } from 'react';
import { Form, FloatingLabel, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Dropzone } from '../molecules/Dropzone';
import { ImageCard } from '../molecules/ImageCard';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  contentsFlg?: boolean;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId?: React.Dispatch<React.SetStateAction<number>>;
}
export const MonitorRuleSetNotesContents: React.VFC<Props> = ({
  data,
  setData,
  rowData,
  index,
  contentsFlg,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  const [imgUrl, setImgUrl] = useState<string | undefined>(
    data?.monitorRuleList?.find((supplement) => {
      return supplement.monitorRuleType === rowData.monitorRuleType;
    })?.imageURL
  );

  const defaultApplyContent = getDefaultApplyContent(data.monitorRuleSetType);

  useEffect(() => {
    setImgUrl(
      data?.monitorRuleList?.find((supplement) => {
        return supplement.monitorRuleType === rowData.monitorRuleType;
      })?.imageURL
    );
  }, [
    data?.monitorRuleList?.find((supplement) => {
      return supplement.monitorRuleType === rowData.monitorRuleType;
    })?.imageURL,
  ]);

  // 画像添付イベント
  const onImgDrop = (acceptedFiles: any) => {
    const createObject = window.webkitURL.createObjectURL;
    // 1ファイルの場合の処理
    if (acceptedFiles.length !== 0 && acceptedFiles.length === 1) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        setData({
          ...data,
          monitorRuleList: data?.monitorRuleList.map((_, i) => {
            return i === index
              ? { ..._, imageId: 1, imageURL: reader.result?.toString() === undefined ? '' : reader.result.toString() }
              : { ..._ };
          }),
        });
      };
    }
  };

  function getDefaultApplyContent(ruleSetType: number) {
    if (ruleSetType === 5) {
      return `モニター調査に伴い撮影した全ての画像、および調査証明の原本は、承認完了後「2ヶ月間」保管をお願い致します。
後日不備が確認された場合や店舗から照会依頼があった場合など、別途提出をお願いすることがございます。
ご提出いただけない場合、謝礼は取り消しとなりますのでご注意ください。

当サービスは覆面調査のサービスですので、決してモニターであることは知られないようにご注意ください。
モニター調査時の振る舞いや言動には十分注意して調査をお願いします。
以下の内容が確認された場合は、謝礼の対象にならないだけでなく、今後の当サービスの利用をご遠慮いただく場合がございます。
・店舗にモニター調査員であることを明かす行為
・モニターの画面を見せながら、商品に関することについての質問等を、お店の方やメーカーにする行為
・モニター調査時の振る舞いや言動に対して、店舗やメーカーからクレームがあった場合
・商品の転売が確認された場合
・商品の返品（返金）が確認された場合`;
    }
    return '';
  }

  // 画像削除イベント
  const onImageDelete = () => {
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((row, inde) => {
        return inde === index ? { ...row, imageId: undefined, imageURL: undefined } : { ...row };
      }),
    });
  };

  const iconOptions: [string, number][] = Object.entries({
    飲食調査: 1,
    電話調査: 2,
    写真撮影: 3,
    トイレ調査: 4,
    スタッフ調査: 5,
    外観調査: 6,
    施術調査: 7,
    購入店舗: 8,
    商品調査: 9,
    体験調査: 10,
    清掃調査: 11,
    予約調査: 12,
    その他: 99,
  });

  return (
    <>
      <div className={contentsFlg === undefined ? 'flex-grow-1 ms-3' : 'flex-grow-1'}>
        <FloatingLabel controlId="monitorRuleType" label="アイコン" className="mb-4">
          <Form.Select
            value={
              data.monitorRuleList.find((_, ind) => {
                return ind === index;
              })?.iconType || ''
            }
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return inde === index ? { ...row, iconType: Number(e.target.value) } : { ...row };
                }),
              });
            }}
          >
            <option value="">&nbsp;</option>
            {iconOptions.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
        <div className="d-flex mb-4">
          <Button
            variant="secondary"
            onClick={() => {
              const type = data.monitorRuleSetType;

              let reflectionText =
                type === 6
                  ? 'モニター調査に伴い撮影した全ての画像、および調査証明の原本は、承認完了後「2ヶ月間」保管をお願い致します。\n後日不備が確認された場合や店舗から照会依頼があった場合など、別途提出をお願いすることがございます。\nご提出いただけない場合、謝礼は取り消しとなりますのでご注意ください。\n\n店舗判断により、「指定メニュー・コース」の施術や体験を受けていただけない場合、モニター調査はできません。\n条件・注意点などは、店舗にご確認ください。\n\n当サービスは覆面調査のサービスですので、決してモニターであることは知られないようにご注意ください。\nモニター調査時の振る舞いや言動には十分注意して調査をお願いします。\n以下の内容が確認された場合は、謝礼の対象にならないだけでなく、今後の当サービスの利用をご遠慮いただく場合がございます。\n・店舗にモニター調査での来店であることを明かしたり、モニターに関しての質問等をする行為\n・モニター調査時の店舗での振る舞いや言動に対して、店舗からクレームがあった場合\n・当選者ご本人が施術を受けず、他者がモニター調査をしていた場合'
                  : 'モニター調査に伴い撮影した全ての画像、および調査証明の原本は、承認完了後「2ヶ月間」保管をお願い致します。\n後日不備が確認された場合や店舗から照会依頼があった場合など、別途提出をお願いすることがございます。\nご提出いただけない場合、謝礼は取り消しとなりますのでご注意ください。\n\n当サービスは覆面調査のサービスですので、決してモニターであることは知られないようにご注意ください。\nモニター調査時の振る舞いや言動には十分注意して調査をお願いします。\n以下の内容が確認された場合は、謝礼の対象にならないだけでなく、今後の当サービスの利用をご遠慮いただく場合がございます。\n・店舗にモニター調査での来店であることを明かしたり、モニターに関しての質問等をする行為\n・モニター調査時の店舗での振る舞いや言動に対して、店舗からクレームがあった場合';

              // リアル案件の場合、文言を追加する。
              if (type === 1 || type === 2 || type === 3 || type === 6) {
                reflectionText = `${reflectionText}\n・店舗に対する迷惑行為と当社が判断した場合（予約の無断キャンセルや遅刻、その他マナー・モラルに反する行為）`;
              }

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row) => {
                  return row.monitorRuleType === 16
                    ? {
                        ...row,
                        beforeApplyContent: undefined,
                        afterApplyContent: reflectionText,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </div>
        <div className="d-flex mb-4">
          <FloatingLabel controlId="afterApplyContent" label="応募後 文言" className="flex-grow-1">
            <FormControl
              as="textarea"
              style={{ height: '200px' }}
              placeholder="応募後 文言"
              value={
                data.monitorRuleList.find((_, ind) => {
                  return ind === index;
                })?.afterApplyContent || defaultApplyContent
              }
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                    return inde === index
                      ? { ...row, afterApplyContent: e.target.value === '' ? undefined : e.target.value }
                      : { ...row };
                  }),
                });
              }}
            />
          </FloatingLabel>
        </div>
        {data.monitorRuleList
          .find((_, ind) => {
            return ind === index;
          })
          ?.supplementList?.map((_, i) => {
            return (
              <div key={i.toString()} className="d-flex mb-4">
                <FloatingLabel controlId="supplement" label="補足" className="flex-grow-1">
                  <FormControl
                    as="textarea"
                    style={{ height: '150px' }}
                    placeholder="補足"
                    value={
                      data.monitorRuleList.find((_row, ind) => {
                        return ind === index;
                      })?.supplementList?.[i].supplement || ''
                    }
                    onChange={(e) => {
                      const copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                        [],
                        data.monitorRuleList.find((row, ind) => {
                          return ind === index;
                        })?.supplementList
                      );

                      setData({
                        ...data,
                        monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                          return inde === index
                            ? {
                                ...row,
                                supplementList: copySupplementList.map((supple, ind) => {
                                  return ind === i ? { ...supple, supplement: e.target.value } : { ...supple };
                                }),
                              }
                            : { ...row };
                        }),
                      });
                    }}
                  />
                </FloatingLabel>
                {i !== 0 && (
                  <Button
                    data-testid="supplementDeleteButton"
                    className="text-secondary bg-transparent border-0"
                    onClick={() => {
                      const copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                        [],
                        data.monitorRuleList.find((row, ind) => {
                          return ind === index;
                        })?.supplementList
                      );

                      setData({
                        ...data,
                        monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                          return inde === index
                            ? {
                                ...row,
                                supplementList: copySupplementList.filter((supplement, ind) => {
                                  return ind !== i;
                                }),
                              }
                            : { ...row };
                        }),
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                  </Button>
                )}
              </div>
            );
          })}
        <div className="d-flex justify-content-end mb-4">
          <Button
            data-testid="supplementAddButton"
            variant="link"
            className="text-secondary p-0"
            onClick={() => {
              const copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((_, ind) => {
                  return ind === index;
                })?.supplementList
              );
              copySupplementList.push({
                id: undefined,
                supplement: '',
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, supplementList: copySupplementList } : { ..._ };
                }),
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            <span style={{ fontSize: '1rem' }}>追加</span>
          </Button>
        </div>
        {imgUrl ? <ImageCard url={imgUrl} onDelete={onImageDelete} /> : <Dropzone onDrop={onImgDrop} />}
      </div>
    </>
  );
};
