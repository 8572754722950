import { AxiosResponse } from 'axios';
import Select, { MultiValue } from 'react-select';
import { faChevronLeft, faTrashAlt, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Modal, Modal as CustomModal } from '../../molecules/Modal';
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
  ShopApi,
  ShopInfoOutputResponse,
  ShopListOutputResponse,
  ShopUpdateFormResponse,
} from '../../../api-client';
import { OptionType } from '../../organisms/Modal/ShopUpdateModal';
import { useLargeState } from '../../../hooks/useLargeState';

interface Prefecture {
  id: number;
  name: string;
}

const initialData = {
  id: 0,
  name: '',
  nameKana: '',
  prefecturesSelectedList: [],
  contractShopList: [],
};

const initialUpdData = {
  indexFlg: true,
};

export interface ModalState {
  isMessageModal: boolean;
  message: string;
  option: OptionType[];
}

export const ShopConfirmPage: React.VFC = () => {
  const [data, setData] = useState<Partial<ShopInfoOutputResponse>>(initialData);
  const [deleteResult, setDeleteResult] = useState<IncResultOutputResponse>();
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [updParam, setUpdParam] = useState<Partial<ShopUpdateFormResponse>>(initialUpdData);
  const [categories, setCategories] = useState<CommonMasterListOutputResponse[]>();
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();
  const [genres1, setGenres1] = useState<CommonMasterListOutputResponse[]>();
  const [genres2, setGenres2] = useState<CommonMasterListOutputResponse[]>();

  const [shops, setShops] = useState<ShopListOutputResponse[]>();

  const shopApi = new ShopApi();
  const commonMasterListApi = new CommonMasterListApi();

  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const [isModal, setIsModal] = useState<boolean>(false);
  // 保存ボタンフラグ
  const [keepFlg, setKeepFlg] = useState<boolean>(false);

  const { state: $modal, mergeState } = useLargeState<ModalState>({
    isMessageModal: false,
    message: '',
    option: [],
  });

  // カテゴリ:美容
  const CATEGORY_BEAUTY_ID = 2;

  const [genre2DisabledFlg, setGenre2DisabledFlg] = useState<boolean>(false);

  useEffect(() => {
    shopApi.shopInfo(Number(id)).then((res: AxiosResponse<ShopInfoOutputResponse>) => {
      setData(res.data);

      setUpdParam({
        id: res.data.id,
        name: res.data.name || '',
        nameKana: res.data.nameKana || '',
        introduction: res.data.introduction,
        prefecturesSelectedList: res.data.prefecturesSelectedList?.map((p) => p.id) || [],
        categoryId: res.data.categoryId,
        phoneNumber: res.data.phoneNumber,
        zipCode: res.data.zipCode,
        address: res.data.address,
        longitude: res.data.longitude,
        latitude: res.data.latitude,
        access: res.data.access,
        indexFlg: res.data.indexFlg,
        genres1SelectedList: res.data.genre1SelectedList || [],
        genres2SelectedList: res.data.genre2SelectedList || [],
        shopURL: res.data.url || '',
        sameShops: res.data.sameShops?.map((p) => p.id) || [],
      });
      setDeleteResult(undefined);
      setKeepFlg(false);

      // カテゴリ美容が設定されていた場合、ジャンル2は非活性にする。
      setGenre2DisabledFlg(res.data.categoryId === CATEGORY_BEAUTY_ID);

      Promise.all(
        [{ key: 'category' }, { key: 'prefecture' }, { key: 'genre', type: 1 }, { key: 'genre', type: 2 }].map(
          ({ key, type }): Promise<CommonMasterListOutputResponse[]> =>
            new Promise((resolve, reject) => {
              commonMasterListApi
                .commonMasterList(key, type)
                .then((res2: AxiosResponse<CommonMasterListOutputResponse[]>) => resolve(res2.data))
                .catch((err: any) => reject(new Error(err)));
            })
        )
      ).then(([allCategories, allPrefectures, allGenres1, allGenres2]) => {
        setCategories(allCategories);
        setGenres1(allGenres1);
        setGenres2(allGenres2);

        // 初期表示時にprefecturesSelectedListに含まれている都道府県をprefecturesから除外する
        const filteredPrefectures = allPrefectures.filter(
          (prefecture) => !res.data.prefecturesSelectedList?.find((selected) => selected.id === prefecture.id)
        );

        setPrefectures(filteredPrefectures);
      });
    });
  }, [id]);

  useEffect(() => {
    shopApi.shopList(undefined, undefined, undefined).then((res) => {
      setShops(res.data);
    });
  }, []);

  // 保存ボタンイベント
  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const genres1InputFlg =
      updParam.genres1SelectedList && updParam.genres1SelectedList.filter((value) => value.id !== 0).length !== 0;

    const genres2InputFlg =
      updParam.genres2SelectedList && updParam.genres2SelectedList.filter((value) => value.id !== 0).length !== 0;
    const isNotLatitude = Boolean(!updParam.latitude && updParam.longitude);
    const isNotLongitude = Boolean(updParam.latitude && !updParam.longitude);

    const emptyFlgList = [!genres2InputFlg && !genre2DisabledFlg];

    if (updParam.categoryId === CATEGORY_BEAUTY_ID && !genres1InputFlg) {
      emptyFlgList.push(true);
    } else {
      emptyFlgList.push(false);
    }

    if (isNotLatitude) emptyFlgList.push(isNotLatitude);
    if (isNotLongitude) emptyFlgList.push(isNotLongitude);

    if (emptyFlgList.some((flg) => flg)) {
      const labelList = ['ジャンル2'];
      labelList.push('ジャンル1');
      if (isNotLatitude) labelList.push('緯度');
      if (isNotLongitude) labelList.push('経度');
      const emptylabelList: string[] = [];
      emptyFlgList.forEach((flg, i) => {
        if (flg) {
          emptylabelList.push(labelList[i]);
        }
      });
      mergeState({
        message: `${emptylabelList.join('・')}が入力されていません`,
        isMessageModal: true,
      });
      return;
    }

    const updateBody = {
      ...updParam,
      phoneNumber: data.phoneNumber ? data.phoneNumber : undefined,
      zipCode: data.zipCode ? data?.zipCode?.replace('-', '') : undefined,
    };

    shopApi.shopUpdate(updateBody as any).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
      setKeepFlg(true);
      setDeleteResult(undefined);
      history.push('../shop');
    });
  };

  // テキストChangeイベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'shopName':
        setUpdParam({ ...updParam, name: event.target.value });
        break;
      case 'shopNameKana':
        setUpdParam({ ...updParam, nameKana: event.target.value });
        break;
      case 'introduction':
        setUpdParam({ ...updParam, introduction: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'shopURL':
        setUpdParam({ ...updParam, shopURL: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'phoneNumber':
        setUpdParam({ ...updParam, phoneNumber: event.target.value.replace(/[^0-9-]/g, '') });
        setData({ ...data, phoneNumber: event.target.value.replace(/[^0-9-]/g, '') });
        break;
      case 'zipCode':
        setUpdParam({
          ...updParam,
          zipCode: event.target.value ? event.target.value.replace(/[^0-9-]/g, '') : undefined,
        });
        setData({ ...data, zipCode: event.target.value ? event.target.value.replace(/[^0-9-]/g, '') : undefined });
        break;
      case 'address':
        setUpdParam({ ...updParam, address: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'latitude':
        setUpdParam({
          ...updParam,
          latitude: Number.isNaN(Number(event.target.value)) ? undefined : Number(event.target.value),
        });
        break;
      case 'longitude':
        setUpdParam({
          ...updParam,
          longitude: Number.isNaN(Number(event.target.value)) ? undefined : Number(event.target.value),
        });
        break;
      case 'access':
        setUpdParam({ ...updParam, access: event.target.value === '' ? undefined : event.target.value });
        break;
      default:
        break;
    }
  };

  // 削除モーダル（YES）イベント
  const handleClickModalYes = (): void => {
    if (!data.id) return;
    shopApi.shopDelete(data.id).then((res: AxiosResponse<IncResultOutputResponse>) => {
      if (res.data.result) {
        history.push({ pathname: '../shop', state: { result: res.data.result } });
      }
      setDeleteResult(res.data);
    });
    setIsModal(false);
    setKeepFlg(false);
  };

  // 削除モーダル（No）イベント
  const handleClickModalNo = (): void => {
    setIsModal(false);
  };

  /**
   * 都道府県選択
   * @param e
   */
  // const onChangePref = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   // e.targetがHTMLInputElementであることを確認する
  //   const inputElement = e.target as HTMLInputElement;
  //
  //   // select-multipleの場合のみ処理を実行
  //   if (inputElement.type === "select-multiple") {
  //     // 選択されたオプションの値を取得
  //     const selectedOptions = Array.from(inputElement.querySelectorAll('option:checked')).map(option => (option as HTMLOptionElement).value);
  //
  //     // 選択された都道府県の情報を生成してデータにセットする
  //     const selectedPrefectures: CommonMasterListOutputResponse[] = selectedOptions.map((optionId) => {
  //       // selectedPrefectureが存在し、idとnameがundefinedでないことを確認します
  //       return { id: parseInt(optionId, 10), name: '' };
  //     });
  //
  //     // データの更新
  //     setData({ ...data, prefecturesSelectedList: selectedPrefectures });
  //
  //     // 更新パラメータの設定
  //     setUpdParam({ ...updParam, prefecturesSelectedList: selectedOptions.map(optionId => parseInt(optionId, 10)) });
  //   }
  // };

  const handleMovePref = (moveRight: boolean) => {
    const radix = 10;

    // moveRight が true の場合は右へ移動する処理を実行
    if (moveRight) {
      const selectedOptions = document.getElementById('prefectures')?.querySelectorAll('option:checked');

      // 選択された都道府県の情報を生成してデータにセットする
      if (selectedOptions) {
        const selectedPrefectures: CommonMasterListOutputResponse[] = Array.from(selectedOptions).map((option) => ({
          id: parseInt(option.getAttribute('value') || '0', radix),
          name: option.textContent || '',
        }));

        // 元の選択された都道府県リストに新しく選択された都道府県を追加
        const updatedPrefecturesSelectedList = [...(data.prefecturesSelectedList || []), ...selectedPrefectures];

        // 選択された都道府県リストをIDでソートする
        updatedPrefecturesSelectedList.sort((a, b) => a.id - b.id);

        const selectedPrefectureIds = selectedPrefectures.map((prefecture) => prefecture.id);
        const updatedPrefectures = prefectures?.filter((pref) => !selectedPrefectureIds.includes(pref.id));

        // データの更新
        setData({ ...data, prefecturesSelectedList: updatedPrefecturesSelectedList });
        setPrefectures(updatedPrefectures);

        // 更新パラメータの設定
        setUpdParam({
          ...updParam,
          prefecturesSelectedList: updatedPrefecturesSelectedList.map((prefecture) => prefecture.id),
        });
      }
    } else {
      // 左への移動処理
      // 選択されたアイテムで選択された都道府県をprefecturesSelectedListから削除し、prefecturesに追加する
      const selectedOptions = document.getElementById('selectedPrefectures')?.querySelectorAll('option:checked');

      // 選択された都道府県の情報を生成してデータにセットする
      if (selectedOptions) {
        const selectedPrefectures: CommonMasterListOutputResponse[] = Array.from(selectedOptions).map((option) => ({
          id: parseInt(option.getAttribute('value') || '0', radix),
          name: option.textContent || '',
        }));

        // 選択された都道府県を「選択されたアイテム」から削除し、「こちらからアイテムを選択」に追加
        const updatedPrefecturesSelectedList = data.prefecturesSelectedList?.filter(
          (prefecture) => !selectedPrefectures.find((selectedPref) => selectedPref.id === prefecture.id)
        );

        const updatedPrefectures = [...(prefectures || []), ...selectedPrefectures];
        // 都道府県リストをIDでソートする
        updatedPrefectures?.sort((a, b) => a.id - b.id);

        // データの更新
        setData({ ...data, prefecturesSelectedList: updatedPrefecturesSelectedList });
        setPrefectures(updatedPrefectures);
        // 更新パラメータの設定
        setUpdParam({
          ...updParam,
          prefecturesSelectedList: updatedPrefecturesSelectedList?.map((prefecture) => prefecture.id),
        });
      }
    }
  };

  const onChangeCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setData((prev) => ({
      ...prev,
      categoryId: Number(event.target.value),
      genre1SelectedList: [],
      genre2SelectedList: [],
    }));

    setUpdParam((prev) => ({
      ...prev,
      categoryId: Number(event.target.value),
      genres1SelectedList: [],
      genres2SelectedList: [],
    }));

    setGenre2DisabledFlg(event.target.value === CATEGORY_BEAUTY_ID.toString());
  };

  const onChangeGenreMain = (event: React.ChangeEvent<HTMLSelectElement>, name: string) => {
    if (name === 'genre1SelectedList') {
      setData({
        ...data,
        [name]: [
          ...(data.genre1SelectedList?.filter((g) => !g.mainFlg) || []),
          { id: Number(event.target.value), mainFlg: true },
        ],
      });

      setUpdParam({
        ...updParam,
        genres1SelectedList: [
          ...(data.genre1SelectedList?.filter((g) => !g.mainFlg) || []),
          { id: Number(event.target.value), mainFlg: true },
        ],
      });
    } else {
      setData({
        ...data,
        [name]: [
          ...(data.genre2SelectedList?.filter((g) => !g.mainFlg) || []),
          { id: Number(event.target.value), mainFlg: true },
        ],
      });

      setUpdParam({
        ...updParam,
        genres2SelectedList: [
          ...(data.genre2SelectedList?.filter((g) => !g.mainFlg) || []),
          { id: Number(event.target.value), mainFlg: true },
        ],
      });
    }
  };

  const onChangeGenreSub = (value: MultiValue<CommonMasterListOutputResponse>, name: string) => {
    if (name === 'genre1SelectedList') {
      setData({
        ...data,
        [name]: [
          ...(data.genre1SelectedList?.filter((g) => g.mainFlg) || []),
          ...value.map((g) => ({ id: g.id, mainFlg: false })),
        ],
      });

      setUpdParam({
        ...updParam,
        genres1SelectedList: [
          ...(data.genre1SelectedList?.filter((g) => g.mainFlg) || []),
          ...value.map((g) => ({ id: g.id, mainFlg: false })),
        ],
      });
    } else {
      setData({
        ...data,
        [name]: [
          ...(data.genre2SelectedList?.filter((g) => g.mainFlg) || []),
          ...value.map((g) => ({ id: g.id, mainFlg: false })),
        ],
      });

      setUpdParam({
        ...updParam,
        genres2SelectedList: [
          ...(data.genre2SelectedList?.filter((g) => g.mainFlg) || []),
          ...value.map((g) => ({ id: g.id, mainFlg: false })),
        ],
      });
    }
  };

  // 同一店舗設定チェンジイベント
  const onChangeSameShopSelect = (selectedContents: MultiValue<{ shopId: number; shopName: string }>) => {
    setData({ ...data, sameShops: selectedContents.map((s) => ({ id: s.shopId, name: s.shopName })) });
    setUpdParam({ ...updParam, sameShops: selectedContents.map((s) => s.shopId) });
    setUpdResult(undefined);
  };

  return (
    <>
      <Modal
        centered
        isModal={$modal.isMessageModal}
        body={
          <>
            <p className="text-center">{$modal.message}</p>
            <div className="text-center">
              <Button
                onClick={() => {
                  mergeState({ isMessageModal: false });
                }}
              >
                OK
              </Button>
            </div>
          </>
        }
      />
      <CustomModal
        title="削除してよろしいですか？"
        footer={
          <>
            <Button variant="secondary" onClick={handleClickModalNo}>
              いいえ
            </Button>
            <Button variant="primary" onClick={handleClickModalYes}>
              はい
            </Button>
          </>
        }
        isModal={isModal}
        onHide={handleClickModalNo}
      />
      <Form onSubmit={handleOnSubmit}>
        <h3 style={{ marginBottom: '20px' }}>実店舗編集</h3>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {keepFlg && updResult?.result && (
            <Alert variant="success" style={{ marginBottom: '24px' }}>
              保存が完了しました。
            </Alert>
          )}
          {keepFlg && updResult !== undefined && updResult?.result === false && (
            <Alert
              variant="danger"
              style={{ marginTop: 10 }}
            >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
          )}
          {deleteResult !== undefined && deleteResult?.result === false && (
            <Alert
              variant="danger"
              style={{ marginTop: 10 }}
            >{`${deleteResult.errorMessage} (エラーコード：${deleteResult.errorCode})`}</Alert>
          )}
          <div style={{ display: 'flex', order: 1 }}>
            <Card style={{ marginTop: '24px', width: 'calc(100%)', height: 'calc(100%)' }}>
              <Card.Header>
                <h4 style={{ margin: 'auto' }}>契約店舗情報</h4>
              </Card.Header>
              <Card.Body>
                {data.contractShopList?.map((contractShop, index) => (
                  <div key={index}>
                    <Form.Group>
                      <Form.Label className="fw-bold">契約店舗名</Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>{contractShop.name}</Form.Label>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="fw-bold">契約店舗電話番号</Form.Label>
                    </Form.Group>
                  </div>
                ))}
              </Card.Body>
            </Card>
          </div>
          <div style={{ display: 'flex', order: 2 }}>
            <Card style={{ marginTop: '24px', width: 'calc(100%)', height: 'calc(100%)' }}>
              <Card.Header>
                <h4 style={{ margin: 'auto' }}>実店舗情報</h4>
              </Card.Header>
              <Card.Body>
                <Form.Group>
                  <Form.Label className="fw-bold">実店舗ID</Form.Label>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>{data?.id}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-4" controlId="shopName">
                  <Form.Label className="fw-bold">
                    実店舗名<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control defaultValue={data?.name} type="text" onChange={onChangeText} onInput={onChangeText} />
                </Form.Group>
                <Form.Group className="mb-4" controlId="shopNameKana">
                  <Form.Label className="fw-bold">
                    実店舗名ふりがな<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={data?.nameKana}
                    onChange={onChangeText}
                    onInput={onChangeText}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="introduction">
                  <Form.Label className="fw-bold">紹介文</Form.Label>
                  <Form.Control
                    as="textarea"
                    defaultValue={data?.introduction}
                    onChange={onChangeText}
                    onInput={onChangeText}
                    rows={5}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="shopURL">
                  <Form.Label className="fw-bold">実店舗URL</Form.Label>
                  <Form.Control type="text" defaultValue={data?.url} onChange={onChangeText} onInput={onChangeText} />
                </Form.Group>
                <Row className="mb-4">
                  <Form.Label className="fw-bold">複数都道府県設定</Form.Label>
                  <Col style={{ height: '400px' }}>
                    <Form.Group className="mb-4" controlId="prefectures">
                      <Form.Label className="fw-bold">こちらからアイテムを選択</Form.Label>
                      <Form.Control as="select" multiple style={{ height: '360px' }}>
                        {prefectures?.map((prefecture) => (
                          <option key={prefecture.id} value={prefecture.id.toString()}>
                            {prefecture.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xs="auto" className="d-flex align-items-center">
                    {' '}
                    {/* 左右の列の中央揃え */}
                    <div className="d-grid">
                      <Button variant="light" onClick={() => handleMovePref(true)}>
                        <FontAwesomeIcon icon={faArrowRight} /> {/* 右向き矢印 */}
                      </Button>
                      <Button variant="light" onClick={() => handleMovePref(false)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                        {/* 左向き矢印 */}
                      </Button>
                    </div>
                  </Col>
                  <Col style={{ height: '400px' }}>
                    <Form.Group className="mb-4" controlId="selectedPrefectures">
                      <Form.Label className="fw-bold">選択されたアイテム</Form.Label>
                      <Form.Control
                        as="select"
                        multiple
                        // value={data.prefecturesSelectedList?.map(prefecture => prefecture.id.toString()) || []}
                        // value={selectedPrefectures2?.map(prefecture => prefecture.id.toString()) || []}
                        style={{ height: '360px' }}
                      >
                        {data.prefecturesSelectedList?.map((prefecture) => (
                          <option key={prefecture.id} value={prefecture.id.toString()}>
                            {prefecture.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="mb-4">
                  <Form.Label className="fw-bold">
                    カテゴリ<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select value={data.categoryId || ''} onChange={onChangeCategory}>
                    {categories?.map((c) => (
                      <option key={c.id} value={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                {(['genre1SelectedList', 'genre2SelectedList'] as const).map((name, i) => (
                  <div className="mb-4" key={name}>
                    <Form.Label className="fw-bold">
                      {`ジャンル${i + 1}`}
                      {(() => {
                        if (data.categoryId === CATEGORY_BEAUTY_ID && i === 0) {
                          return <span className="text-danger">*</span>;
                        }
                        if (data.categoryId !== CATEGORY_BEAUTY_ID && i === 1) {
                          return <span className="text-danger">*</span>;
                        }
                      })()}
                    </Form.Label>
                    <div className="bg-light p-3">
                      <Form.Group className="mb-4">
                        <Form.Label className="fw-bold">メイン</Form.Label>
                        <Form.Select
                          value={data[name]?.find(({ mainFlg }) => mainFlg)?.id || ''}
                          onChange={(event) => onChangeGenreMain(event, name)}
                          disabled={i === 1 && genre2DisabledFlg}
                        >
                          <option value="">&nbsp;</option>
                          {(i === 0 ? genres1 : genres2)
                            ?.filter((g) => g.categoryId === data.categoryId)
                            ?.filter(
                              (g) =>
                                !data[name]
                                  ?.filter(({ mainFlg }) => !mainFlg)
                                  ?.map((s) => s.id)
                                  .includes(g.id)
                            )
                            ?.map((g) => (
                              <option key={g.id} value={g.id}>
                                {g.name}
                              </option>
                            ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="fw-bold">サブ</Form.Label>
                        <Select
                          isMulti
                          id={`subGenre${i + 1}`}
                          onChange={(event) => onChangeGenreSub(event, name)}
                          isDisabled={i === 1 && genre2DisabledFlg}
                          value={(i === 0 ? genres1 : genres2)
                            ?.filter((g) => g.id !== data[name]?.find((s) => s.mainFlg)?.id)
                            ?.filter((g) => data[name]?.map((s) => s.id).includes(g.id))}
                          getOptionLabel={({ name: oName }) => oName}
                          getOptionValue={({ id: oId }) => String(oId)}
                          options={(i === 0 ? genres1 : genres2)
                            ?.filter((g) => g.categoryId === data.categoryId)
                            ?.filter((g) => !data[name]?.map((s) => s.id).includes(g.id))}
                          closeMenuOnSelect={false}
                          placeholder=""
                        />
                      </Form.Group>
                    </div>
                  </div>
                ))}

                <Form.Group className="mb-4 mt-4" controlId="phoneNumber">
                  <Form.Label className="fw-bold">実店舗電話番号</Form.Label>
                  <Form.Control
                    value={data.phoneNumber || ''}
                    type="text"
                    onChange={onChangeText}
                    onInput={onChangeText}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="zipCode">
                  <Form.Label className="fw-bold">実店舗郵便番号</Form.Label>
                  <Form.Control value={data.zipCode || ''} type="text" onChange={onChangeText} onInput={onChangeText} />
                </Form.Group>
                <Form.Group className="mb-4" controlId="address">
                  <Form.Label className="fw-bold">実店舗住所</Form.Label>
                  <Form.Control
                    defaultValue={data.address}
                    type="text"
                    onChange={onChangeText}
                    onInput={onChangeText}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="latitude">
                  <Form.Label className="fw-bold">緯度</Form.Label>
                  <Form.Control
                    defaultValue={data.latitude}
                    type="number"
                    step={0.00000000000001}
                    onChange={onChangeText}
                    onInput={onChangeText}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="longitude">
                  <Form.Label className="fw-bold">経度</Form.Label>
                  <Form.Control
                    defaultValue={data.longitude}
                    type="number"
                    step={0.00000000000001}
                    onChange={onChangeText}
                    onInput={onChangeText}
                  />
                </Form.Group>
                <Form.Group className="mb-4" controlId="access">
                  <Form.Label className="fw-bold">アクセス</Form.Label>
                  <Form.Control defaultValue={data.access} type="text" onChange={onChangeText} onInput={onChangeText} />
                </Form.Group>
                <Form.Group controlId="sameShops" className="mb-4">
                  <Form.Label className="fw-bold">同一店舗設定</Form.Label>
                  <Select
                    id="sameShop"
                    className="mb-4"
                    value={data.sameShops?.map((d) => ({ shopId: d.id, shopName: d.name }))}
                    isMulti
                    onChange={(e) => onChangeSameShopSelect(e)}
                    closeMenuOnSelect={false}
                    getOptionLabel={(option: any) => `${option.shopName}（${option.shopId}) `}
                    getOptionValue={(option: any) => option.shopId}
                    options={shops?.map((d) => ({ shopId: d.id, shopName: d.name }))}
                    placeholder="同一店舗を設定する。"
                  />
                </Form.Group>
                <Form.Group controlId="indexFlg" className="d-flex justify-content-between">
                  <Form.Label className="fw-bold">Google/Yahoo検索表示フラグ</Form.Label>
                  <Form.Check
                    type="switch"
                    id="indexFlg"
                    checked={updParam.indexFlg ?? false}
                    onChange={() => setUpdParam((prev) => ({ ...prev, indexFlg: !prev.indexFlg }))}
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
          <div className="position-sticky" style={{ top: '-1.5rem', zIndex: 2 }}>
            <Card>
              <Table style={{ margin: 'auto' }}>
                <tbody>
                  <tr className="align-middle">
                    <td style={{ textAlign: 'left' }}>
                      <div style={{ verticalAlign: 'center', marginLeft: '0.4rem' }}>
                        <Button
                          variant="link"
                          className="text-secondary"
                          onClick={() => history.push('../shop')}
                          style={{ paddingLeft: 0 }}
                        >
                          <FontAwesomeIcon icon={faChevronLeft} fixedWidth style={{ verticalAlign: -2 }} />
                          戻る
                        </Button>
                      </div>
                    </td>
                    <td style={{ textAlign: 'right', paddingLeft: 0 }}>
                      <div style={{ verticalAlign: 'center', marginRight: '1.0rem', paddingRight: 0 }}>
                        <Button variant="link" className="text-secondary" onClick={() => setIsModal(true)}>
                          <FontAwesomeIcon icon={faTrashAlt} fixedWidth className="me-1" style={{ fontSize: '1rem' }} />
                          削除
                        </Button>
                        <Button id="keep" type="submit" style={{ textAlign: 'center' }}>
                          保存
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      </Form>
    </>
  );
};
