import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Col, InputGroup, ListGroup, Tabs, Tab } from 'react-bootstrap';

import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { SEARCH_TYPE } from '../../pages/Brand/BrandDetailModifyPage';
import { BrandApi, CampaignApi, TargetMonitorbaseListOutputResponse } from '../../../api-client';

const sampleSearchData = {
  searchShopList: [
    { shopId: 11, shopName: '店舗11' },
    { shopId: 12, shopName: '店舗12' },
  ],
  shopList: [],
};

const sampleSearchData2 = {
  searchShopList: [{ shopId: 5, shopName: '店舗5' }],
  shopList: [],
};

const sampleGetData = {
  searchShopList: [...Array(30)].map((_, i) => {
    return { shopId: i + 1, shopName: `店舗${i + 1}` };
  }),
  shopList: [
    { shopId: 1, shopName: '店舗1' },
    { shopId: 2, shopName: '店舗2' },
    { shopId: 3, shopName: '店舗3' },
  ],
};

export interface Props {
  mode: 'brand' | 'campaign';
  id?: number;
  isLinkedMonitorBaseModal: boolean;
  setIsLinkedMonitorBaseModal: React.Dispatch<React.SetStateAction<boolean>>;
  monitorBaseList: {
    monitorBaseId: number;
    monitorBaseName: string;
    shopId?: number;
    shopName?: string;
    clientId?: number;
    clientName?: string;
  }[];
  setMonitorBaseList: React.Dispatch<React.SetStateAction<{ monitorBaseId: number; monitorBaseName: string }[]>>;
}

const TAB_TYPE = {
  ALL: 'all',
  SELECTED: 'selected',
} as const;

const MODE = {
  BRAND: 'brand',
  CAMPAIGN: 'campaign',
} as const;

const MAX_RECORD_PER_PAGE = 20;

export const LinkedMonitorBaseModal: React.VFC<Props> = ({
  mode,
  id,
  isLinkedMonitorBaseModal,
  setIsLinkedMonitorBaseModal,
  monitorBaseList,
  setMonitorBaseList,
}) => {
  const [firstSearchParam, setFirstSearchParam] = useState<{ type: number; keyword: string }>({
    type: 1,
    keyword: '',
  });

  const [secondSearchParam, setSecondSearchParam] = useState<{ type: number; keyword: string }>({
    type: 1,
    keyword: '',
  });

  const [searchList, setSearchList] = useState<
    {
      monitorBaseId: number;
      monitorBaseName: string;
      selected: boolean;
      shopId?: number;
      shopName?: string;
      clientId?: number;
      clientName?: string;
    }[]
  >([]);
  const [searchListPerPage, setSearchListPerPage] = useState<any[]>([]);
  const [searchListCurrentPage, setSearchListCurrentPage] = useState<number>(1);
  const [searchListTotalPage, setSearchListTotalPage] = useState<number>(1);

  const [selectedList, setSelectedList] = useState<
    {
      selected: boolean;
      monitorBaseId: number;
      monitorBaseName: string;
      shopId?: number;
      shopName?: string;
      clientId?: number;
      clientName?: string;
    }[]
  >([]);
  const [selectedListPerPage, setSelectedListPerPage] = useState<any[]>([]);
  const [selectedListCurrentPage, setSelectedListCurrentPage] = useState<number>(1);
  const [selectedListTotalPage, setSelectedListTotalPage] = useState<number>(1);

  const [tabType, setTabType] = useState<'all' | 'selected'>('all');

  const brandApi = new BrandApi();
  const campaignApi = new CampaignApi();

  useEffect(() => {
    const param = {
      typeIds: firstSearchParam.type,
      keywordIds: firstSearchParam.keyword,
      typeName: secondSearchParam.type,
      keywordName: secondSearchParam.keyword,
    };
    if (mode === MODE.BRAND) {
      brandApi.brandTargetMonitorBaseList(param, id).then((res: AxiosResponse<TargetMonitorbaseListOutputResponse>) => {
        // initialSetup(sampleGetData);
        initialSetup(res.data);
      });
    } else if (mode === MODE.CAMPAIGN) {
      campaignApi
        .campaignTargetMonitorBaseList(param, id)
        .then((res: AxiosResponse<TargetMonitorbaseListOutputResponse>) => {
          // initialSetup(sampleGetData);
          initialSetup(res.data);
        });
    }
  }, [isLinkedMonitorBaseModal]);

  const initialSetup = (data: TargetMonitorbaseListOutputResponse | null) => {
    const searchMonitorBaseList = data?.searchMonitorBaseList.map((item) => {
      return {
        ...item,
        selected: monitorBaseList.some((selectedItem) => item.monitorBaseId === selectedItem.monitorBaseId),
      };
    });
    setSearchList(searchMonitorBaseList || []);
    setSearchListPerPage(searchMonitorBaseList?.slice(0, MAX_RECORD_PER_PAGE) || []);
    setSearchListTotalPage(Math.ceil(Number(searchMonitorBaseList?.length) / MAX_RECORD_PER_PAGE));
    setSearchListCurrentPage(1);
    const shopList =
      monitorBaseList.map((item) => {
        return { ...item, selected: true };
      }) || [];
    setSelectedList(shopList);
    setSelectedListPerPage(shopList?.slice(0, MAX_RECORD_PER_PAGE));
    setSelectedListTotalPage(Math.ceil(Number(shopList.length) / MAX_RECORD_PER_PAGE));
    setSelectedListCurrentPage(1);
  };

  const handleClickSearch = (isNameSearch?: boolean) => {
    const param = {
      typeIds: firstSearchParam.type,
      keywordIds: isNameSearch ? '' : firstSearchParam.keyword,
      typeName: secondSearchParam.type,
      keywordName: isNameSearch ? secondSearchParam.keyword : '',
    };
    if (mode === MODE.BRAND) {
      brandApi.brandTargetMonitorBaseList(param, id).then((res: AxiosResponse<TargetMonitorbaseListOutputResponse>) => {
        searchSetup(res.data);
      });
    } else if (mode === MODE.CAMPAIGN) {
      campaignApi
        .campaignTargetMonitorBaseList(param as any, id)
        .then((res: AxiosResponse<TargetMonitorbaseListOutputResponse>) => {
          searchSetup(res.data);
          // searchSetup(isNameSearch ? sampleSearchData2 : sampleSearchData);
        });
    }
  };

  const searchSetup = (data: TargetMonitorbaseListOutputResponse) => {
    const searchShopList =
      data?.searchMonitorBaseList?.map((item) => {
        return {
          ...item,
          selected: selectedList.some((selectedItem) => item.monitorBaseId === selectedItem.monitorBaseId),
        };
      }) || [];
    setSearchList(searchShopList);
    setSearchListPerPage(searchShopList.slice(0, MAX_RECORD_PER_PAGE));
    setSearchListTotalPage(Math.ceil(Number(searchShopList.length) / MAX_RECORD_PER_PAGE));
    setSearchListCurrentPage(1);
  };

  const handleClickSelectAll = () => {
    const nonSeletedSearchList = searchList
      .filter((item) => !item.selected)
      .map((item) => {
        return { ...item, selected: true };
      });
    const newSearchList = searchList.map((item) => {
      return { ...item, selected: true };
    });
    setSearchList(newSearchList);
    setSearchListPerPage(
      newSearchList.slice(
        (searchListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        searchListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );

    const newSelectedList = [...selectedList, ...nonSeletedSearchList].sort(
      (a, b) => a.monitorBaseId - b.monitorBaseId
    );
    setSelectedList(newSelectedList);
    setSelectedListPerPage(
      newSelectedList.slice(
        (selectedListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        selectedListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );
    setSelectedListTotalPage(Math.ceil(Number(newSelectedList.length) / MAX_RECORD_PER_PAGE));
    setSelectedListCurrentPage(1);
  };

  const handleDisableAll = () => {
    const newSearchList = searchList.map((item) => ({ ...item, selected: false }));
    setSearchList(newSearchList);
    setSearchListPerPage(
      newSearchList.slice(
        (searchListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        searchListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );
    setSelectedList([]);
    setSelectedListPerPage([]);
    setSelectedListTotalPage(1);
    setSelectedListCurrentPage(1);
  };

  const handleClickSave = () => {
    setMonitorBaseList(
      selectedList.map((item) => {
        return {
          monitorBaseId: item.monitorBaseId,
          monitorBaseName: item.monitorBaseName,
          shopId: item.shopId,
          shopName: item.shopName,
          clientId: item.clientId,
          clientName: item.clientName,
        };
      })
    );
    onHide();
  };

  const onHide = () => {
    setIsLinkedMonitorBaseModal(false);
    setTabType(TAB_TYPE.ALL);
    setFirstSearchParam({ type: 1, keyword: '' });
    setSecondSearchParam({ type: 1, keyword: '' });
  };

  const handleClickCheckbox = (target: { monitorBaseId: number; monitorBaseName: string; selected: boolean }) => {
    const newSearchList = searchList.map((item) => {
      return item.monitorBaseId === target.monitorBaseId ? { ...target, selected: !target.selected } : item;
    });
    setSearchList(newSearchList);
    setSearchListPerPage(
      newSearchList.slice(
        (searchListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        searchListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );

    let newSelectedList;
    if (tabType === TAB_TYPE.ALL && !target.selected) {
      newSelectedList = [...selectedList, { ...target, selected: true }].sort(
        (a, b) => a.monitorBaseId - b.monitorBaseId
      );
    } else {
      newSelectedList = selectedList.filter((item) => item.monitorBaseId !== target.monitorBaseId);
    }
    setSelectedList(newSelectedList);
    setSelectedListPerPage(
      newSelectedList.slice(
        (selectedListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        selectedListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );
    setSelectedListTotalPage(Math.ceil(Number(newSelectedList.length) / MAX_RECORD_PER_PAGE));
  };

  const renderListItem = (): JSX.Element[] => {
    const list = tabType === TAB_TYPE.ALL ? searchListPerPage : selectedListPerPage;

    return list.map((item) => {
      return (
        <ListGroup.Item key={item.monitorBaseId}>
          <Form.Check id={String(item.monitorBaseId)} className="d-flex">
            <Form.Check.Input
              className="flex-shrink-0 me-2"
              type="checkbox"
              onChange={() => handleClickCheckbox(item)}
              checked={item.selected}
            />
            <Form.Check.Label className="w-100">
              <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                <div className="form-floating me-3" style={{ width: '10%' }}>
                  {item.monitorBaseId}
                </div>
                <div className="me-3" style={{ width: '15%' }}>
                  {item.monitorBaseName}
                </div>
                <div className="me-3" style={{ width: '7%' }}>
                  {item.shopId}
                </div>
                <div className="me-3" style={{ width: '30%' }}>
                  {item.shopName}
                </div>
                <div className="me-3" style={{ width: '8%' }}>
                  {item.clientId}
                </div>
                <div style={{ width: '30%' }}>{item.clientName}</div>
              </div>
            </Form.Check.Label>
          </Form.Check>
        </ListGroup.Item>
      );
    });
  };

  const renderTabInner = (): JSX.Element => {
    return (
      <>
        <InputGroup className="mb-4">
          <Form.Select
            value={firstSearchParam.type}
            onChange={(e) => setFirstSearchParam({ ...firstSearchParam, type: Number(e.target.value) })}
          >
            <option value="1">{SEARCH_TYPE.CLIENT}</option>
            <option value="2">{SEARCH_TYPE.SHOP}</option>
            <option value="3">{SEARCH_TYPE.BASE}</option>
          </Form.Select>
          <Form.Control
            className="w-50"
            type="text"
            placeholder="IDを入力して下さい（カンマ区切り可）"
            value={firstSearchParam.keyword}
            onChange={(e) => setFirstSearchParam({ ...firstSearchParam, keyword: e.target.value })}
          />
          <Button
            className="flex-shrink-1"
            onClick={() => handleClickSearch()}
            disabled={tabType === TAB_TYPE.SELECTED}
          >
            検索
          </Button>
        </InputGroup>

        <InputGroup className="mb-4">
          <Form.Select
            value={secondSearchParam.type}
            onChange={(e) => setSecondSearchParam({ ...secondSearchParam, type: Number(e.target.value) })}
          >
            <option value="1">{SEARCH_TYPE.CLIENT}</option>
            <option value="2">{SEARCH_TYPE.SHOP}</option>
          </Form.Select>
          <Form.Control
            className="w-50"
            type="text"
            placeholder="名称を入力して下さい"
            value={secondSearchParam.keyword}
            onChange={(e) => setSecondSearchParam({ ...secondSearchParam, keyword: e.target.value })}
          />
          <Button
            className="flex-shrink-1"
            onClick={() => {
              handleClickSearch(true);
            }}
            disabled={tabType === TAB_TYPE.SELECTED}
          >
            検索
          </Button>
        </InputGroup>

        {tabType === TAB_TYPE.ALL && searchList.length > 0 && (
          <div className="mb-4">
            <PaginationWithEllipsis
              currentPage={searchListCurrentPage}
              totalPage={searchListTotalPage}
              handleClick={(page) => {
                if (!page || page > searchListTotalPage) return;
                setSearchListPerPage(searchList.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                setSearchListCurrentPage(page);
              }}
            />
          </div>
        )}
        {tabType === TAB_TYPE.SELECTED && selectedList.length > 0 && (
          <div className="mb-4">
            <PaginationWithEllipsis
              currentPage={selectedListCurrentPage}
              totalPage={selectedListTotalPage}
              handleClick={(page) => {
                if (!page || page > selectedListTotalPage) return;
                setSelectedListPerPage(
                  selectedList.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE)
                );
                setSelectedListCurrentPage(page);
              }}
            />
          </div>
        )}

        <Button size="sm" onClick={handleClickSelectAll} className="mb-4" disabled={tabType === TAB_TYPE.SELECTED}>
          全選択
        </Button>
        <Button size="sm" onClick={handleDisableAll} className="mb-4 mx-1" variant="danger">
          全解除
        </Button>
        <Col style={{ minHeight: '50vh' }}>
          <ListGroup>{renderListItem()}</ListGroup>
        </Col>
      </>
    );
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isLinkedMonitorBaseModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={onHide}>
              キャンセル
            </Button>
            <Button className="ms-2" onClick={handleClickSave}>
              保存
            </Button>
          </div>

          <Tabs
            defaultActiveKey="all"
            activeKey={tabType}
            onSelect={(key) => setTabType(key as 'all' | 'selected')}
            className="mb-4"
          >
            <Tab eventKey={TAB_TYPE.ALL} title="全て">
              {renderTabInner()}
            </Tab>

            <Tab eventKey={TAB_TYPE.SELECTED} title="選択済み">
              {renderTabInner()}
            </Tab>
          </Tabs>
        </>
      }
    />
  );
};
