export const ROLE_NAME = {
  QC_CREW: 'QCクルー',
  DATA_INPUT_TTS: 'データ入力_TTS',
  DATA_INPUT_OTHER: 'データ入力_その他外部',
  SALES: '営業',
  SENIOR_SALES: '営業上長',
};

/**
 * QCクルーや外部データ入力作業者のロールと一致するかを判定する
 * @param role
 */
export const isExternalWorker = (role: string | undefined) => {
  return ROLE_NAME.QC_CREW === role || ROLE_NAME.DATA_INPUT_OTHER === role || ROLE_NAME.DATA_INPUT_TTS === role;
};

export const isInternalWorkerOrQc = (role: string | undefined) => {
  return !(ROLE_NAME.DATA_INPUT_OTHER === role || ROLE_NAME.DATA_INPUT_TTS === role);
};

export const isInternalWorker = (role: string | undefined) => {
  return !isExternalWorker(role);
};

export const isSales = (role: string | undefined) => {
  return [ROLE_NAME.SALES, ROLE_NAME.SENIOR_SALES].includes(role ?? '');
};
