import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「支払方法」Contents
export const MonitorRuleSetPaymentMethodContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 「条件なし」項目
  const [notConditionFlg, setNotConditionFlg] = useState<boolean>(false);

  useEffect(() => {
    if (!data.monitorRuleList[index].paymentTypeCashFlg && !data.monitorRuleList[index].paymentTypeCreditCardFlg) {
      setNotConditionFlg(true);
      return;
    }
    setNotConditionFlg(false);
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((_, i) => {
        return _.monitorRuleType === 12 ? { ..._, paymentTypeCouponOkFlg: false } : { ..._ };
      }),
    });
  }, [data.monitorRuleList[index].paymentTypeCashFlg, data.monitorRuleList[index].paymentTypeCreditCardFlg]);

  return (
    <>
      <div className="d-flex ">
        <Form.Check.Input
          data-testid="notTypePaymentCheckBox"
          className="flex-shrink-0 me-3"
          type="checkbox"
          name="checkBoxGroup01"
          checked={
            notConditionFlg ||
            (!data.monitorRuleList[index].paymentTypeCashFlg && !data.monitorRuleList[index].paymentTypeCreditCardFlg)
          }
          onChange={(e) => {
            // checkが入れられた場合「現金」「クレジットカード」項目のフラグを解除
            if (e.target.checked) {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, paymentTypeCreditCardFlg: false, paymentTypeCashFlg: false } : { ..._ };
                }),
              });
            }
          }}
        />
        <Form.Label className="me-5">条件なし</Form.Label>
        <Form.Check.Input
          data-testid="couponTypePaymentCheckBox"
          className="flex-shrink-0 me-3"
          style={{ marginLeft: '5rem' }}
          type="checkbox"
          name="checkBoxGroup01"
          disabled={!notConditionFlg}
          checked={
            data.monitorRuleList.find((_) => {
              return _.monitorRuleType === 12;
            })?.paymentTypeCouponOkFlg === undefined
              ? false
              : data.monitorRuleList.find((_) => {
                  return _.monitorRuleType === 12;
                })?.paymentTypeCouponOkFlg || false
          }
          onChange={() => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((_, i) => {
                return _.monitorRuleType === 12
                  ? { ..._, paymentTypeCouponOkFlg: !_.paymentTypeCouponOkFlg }
                  : { ..._ };
              }),
            });
          }}
        />
        <Form.Label className="me-2">クーポン利用</Form.Label>
      </div>
      <div>
        <Form.Check.Input
          data-testid="cashTypePaymentCheckBox"
          className="me-3"
          type="checkbox"
          name="checkBoxGroup01"
          checked={
            data.monitorRuleList.find((_) => {
              return _.monitorRuleType === 12;
            })?.paymentTypeCashFlg === undefined
              ? false
              : data.monitorRuleList.find((_) => {
                  return _.monitorRuleType === 12;
                })?.paymentTypeCashFlg || false
          }
          onChange={() => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((_, i) => {
                return _.monitorRuleType === 12 ? { ..._, paymentTypeCashFlg: !_.paymentTypeCashFlg } : { ..._ };
              }),
            });
          }}
        />
        <Form.Label className="me-2">現金（紙幣または貨幣）</Form.Label>
      </div>
      <div className="d-flex">
        <div className="mb-4">
          <Form.Check.Input
            data-testid="creditTypePaymentCheckBox"
            className="flex-shrink-0 me-3"
            type="checkbox"
            name="checkBoxGroup01"
            checked={
              data.monitorRuleList.find((_) => {
                return _.monitorRuleType === 12;
              })?.paymentTypeCreditCardFlg === undefined
                ? false
                : data.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 12;
                  })?.paymentTypeCreditCardFlg || false
            }
            onChange={() => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return _.monitorRuleType === 12
                    ? { ..._, paymentTypeCreditCardFlg: !_.paymentTypeCreditCardFlg }
                    : { ..._ };
                }),
              });
            }}
          />
          <Form.Label className="me-2">クレジットカード</Form.Label>
        </div>
        <div>
          <Button
            style={{ marginLeft: '5.3rem' }}
            variant="secondary"
            onClick={() => {
              let reflectionText = '';
              let supplementText = '';
              const cashFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 12;
              })?.paymentTypeCashFlg;
              const creditCardFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 12;
              })?.paymentTypeCreditCardFlg;
              const cuponFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 12;
              })?.paymentTypeCouponOkFlg;

              // 「条件なし」が選択されていた場合
              if (notConditionFlg) {
                reflectionText = '会計時に利用できる支払い方法に準じます。';
              }
              // 「現金（紙幣または貨幣）」のみが選択されていた場合
              else if (cashFlg && !creditCardFlg) {
                reflectionText = '現金払い（紙幣・貨幣）';
              }
              // 「クレジットカード」のみが選択されていた場合
              else if (!cashFlg && creditCardFlg) {
                reflectionText =
                  '下記のうち、会計時に利用できる支払い方法をご利用ください。\n・クレジットカード払い\n・交通ICカード（Suica、PASMO、Kitaca、ICOCAなど）\n・電子マネー（iD、nanaco、WAON、楽天Edy、QUICPayなど）\n・QR決済（PayPay、d払い、auPAY、楽天Payなど）';
              }
              // 「現金（紙幣または貨幣）」「クレジットカード」が両方選択されていた場合
              else if (cashFlg && creditCardFlg) {
                reflectionText =
                  '下記のうち、会計時に利用できる支払い方法をご利用ください。\n・現金払い（紙幣・貨幣）\n・クレジットカード払い\n・交通ICカード（Suica、PASMO、Kitaca、ICOCAなど）\n・電子マネー（iD、nanaco、WAON、楽天Edy、QUICPayなど）\n・QR決済（PayPay、d払い、auPAY、楽天Payなど）';
              }

              // 「クーポン利用」にチェックがされていない場合
              if (!cuponFlg) {
                supplementText =
                  '※金券・株主優待券・ポイントでのお支払いはおすすめしておりません。\n　利用される場合は必ず事前に<A Href="https://www.fancrew.jp/landing/payment-attention" target="_blank">コチラ</A>をご確認ください。';
              } else {
                supplementText = '※金券・株主優待券・ポイントでのお支払いはおすすめしておりません。';
              }

              let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((supplement) => {
                  return supplement.monitorRuleType === rowData.monitorRuleType;
                })?.supplementList
              );
              copySupplementList = copySupplementList.map((r, i) => {
                return i === 0 ? { ...r, supplement: supplementText } : { ...r };
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 12
                    ? {
                        ...row,
                        beforeApplyContent: undefined,
                        afterApplyContent: reflectionText,
                        supplementList: copySupplementList,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </div>
      </div>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
