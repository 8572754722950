import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { AnswerHistoryListTable } from '../../molecules/AnswerHistoryListTable';
// eslint-disable-next-line import/no-cycle
import { AnswerNgHistoryListOutputResponse, EnqueteCorrectionApi } from '../../../api-client';

export const AnswerHistoryListPage: React.FC = () => {
  const [data, setData] = useState<AnswerNgHistoryListOutputResponse>();
  const { applyId } = useParams<{ applyId: string | undefined }>();
  useEffect(() => {
    new EnqueteCorrectionApi()
      .answerNgHistoryList(Number(applyId))
      .then((res: AxiosResponse<AnswerNgHistoryListOutputResponse>) => {
        setData(res.data);
      });
  }, [applyId]);
  return (
    <>
      <AnswerHistoryListTable data={data} />
    </>
  );
};
