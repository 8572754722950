import { faPlus, faFileUpload, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Tab, Tabs, Alert, Popover, OverlayTrigger } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import {
  CommonMasterListApi,
  EnqueteListApi,
  EnqueteApi,
  EnqueteDownloadApi,
  QuestionCategoryForEachHierarchyListApi,
  QuestionCategoryForEachHierarchyListDownloadApi,
  IdNameOutputResponse,
  RoleListApi,
} from '../../../api-client';
import type {
  CommonMasterListOutputResponse,
  EnqueteListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';
// eslint-disable-next-line import/no-cycle
import { EnqueteListTable } from '../../organisms/Table/EnqueteListTable';
import { Dropzone } from '../../molecules/Dropzone';
import { Modal } from '../../molecules/Modal';
import { execDownload, when } from '../../../utils/functions';

export const ENQUETE_TYPE = {
  BEFOREHAND: 1,
  SELECT: 2,
  PILEUP: 3,
  MEMBERSHIP: 4,
  ENQUETE_MONITOR: 5,
  OTHER: 6,
} as const;

export const EnqueteListPage: React.VFC = () => {
  const [data, setData] = useState<EnqueteListOutputResponse[]>([]);

  const [listPerPage, setListPerPage] = useState<EnqueteListOutputResponse[]>([]);

  const location = useLocation<{ id: number; enqueteType: typeof ENQUETE_TYPE[keyof typeof ENQUETE_TYPE] }>();
  const history = useHistory();

  const [searchParams, setSearchParms] = useState<{
    enqueteId?: number;
    enqueteName?: string;
    questionId?: number;
    questionName?: string;
    client?: string;
    useClient?: string;
    categoryId?: number;
    categoryName?: string;
    businessCategoryId?: number;
    businessCategoryName?: string;
  }>({});

  //  ページ関連変数
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const MAX_RECORD_PER_PAGE = 20;

  // 削除結果
  const [deleteResult, setDeleteResult] = useState<IncResultOutputResponse>();

  //  カテゴリリスト
  const [categoryList, setCategoryList] = useState<CommonMasterListOutputResponse[]>([]);
  // 提供業態リスト
  const [businessCategoryList, setBusinessCategoryList] = useState<CommonMasterListOutputResponse[]>([]);

  // タブKey
  // 1: 「事前」タブ
  // 2: 「事後 選択式」タブ
  // 3: 「事後 積上式」タブ
  // 4: 「事後 その他」タブ
  // 5: 「事後 会員属性」タブ
  // 6: 「事後 アンケートモニター」タブ
  const [enqueteType, setEnqueteType] = useState<number>(1);

  // fileダウンロードモーダル制御
  const [isModal, setIsModal] = useState<boolean>(false);
  const [isOkModal, setIsOkModal] = useState<boolean>(false);

  const enqueteListApi = new EnqueteListApi();
  const enqueteApi = new EnqueteApi();
  const commonApi = new CommonMasterListApi();
  const enqueteDownloadApi = new EnqueteDownloadApi();
  const questionCategoryDownloadApi = new QuestionCategoryForEachHierarchyListDownloadApi();

  const [msg, setMeg] = useState<string | undefined>();

  // 営業ロール保有者フラグ
  const [salesFlg, setSalesFlg] = useState<boolean>(false);
  const roleListApi = new RoleListApi();

  const questionCategoryDownloadTooltip = (
    <Popover id="popover-basic">
      <Popover.Body className="p-1">選択式専用カテゴリ一覧</Popover.Body>
    </Popover>
  );

  useEffect(() => {
    if (location.state && location.state.enqueteType) {
      setEnqueteType(location.state.enqueteType);
      onChangeTopTab(String(location.state.enqueteType));
      enqueteListApi
        .enqueteList(location.state.enqueteType, undefined, undefined, undefined, undefined, undefined)
        .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
          setData(res.data.sort((a, b) => Number(b) - Number(a)));
        });
    }
  }, [location.state]);

  useEffect(() => {
    if (enqueteType === ENQUETE_TYPE.SELECT && categoryList.length === 0) {
      commonApi.commonMasterList('category').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
        setCategoryList(res.data);
      });
    }

    if (enqueteType === ENQUETE_TYPE.PILEUP && businessCategoryList.length === 0) {
      commonApi.commonMasterList('business_category').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
        setBusinessCategoryList(res.data);
      });
    }

    enqueteListApi
      .enqueteList(enqueteType, undefined, undefined, undefined, undefined, undefined)
      .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setData(res.data.sort((a, b) => Number(b) - Number(a)));
        setCurrentPage(1);
      });
  }, [enqueteType]);

  useEffect(() => {
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data.length) / MAX_RECORD_PER_PAGE));
  }, [data, currentPage]);

  // 画面初期表示時に一回だけロール一覧を取得し、営業ロールを持っているか確認する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setSalesFlg(res.data.some((role) => role.name === '営業'));
    });
  }, []);

  // 各種Text系変更イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'enqueteId':
        setSearchParms({
          ...searchParams,
          enqueteId: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'enqueteName':
        setSearchParms({ ...searchParams, enqueteName: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'client':
        setSearchParms({ ...searchParams, client: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'useClient':
        setSearchParms({ ...searchParams, useClient: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'questionId':
        setSearchParms({
          ...searchParams,
          questionId: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'questionName':
        setSearchParms({ ...searchParams, questionName: event.target.value === '' ? undefined : event.target.value });
        break;
      default:
        break;
    }
  };

  // タブ変更イベント
  // param : key 押下したタブキー
  const onChangeTopTab = (key: string | null) => {
    setEnqueteType(Number(key));
    setSearchParms({
      ...searchParams,
      enqueteId: undefined,
      enqueteName: undefined,
      questionId: undefined,
      questionName: undefined,
      client: undefined,
      categoryId: undefined,
      categoryName: undefined,
      businessCategoryId: undefined,
      businessCategoryName: undefined,
    });
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    enqueteListApi
      .enqueteList(
        enqueteType,
        searchParams.enqueteId,
        searchParams.enqueteName,
        searchParams.questionId,
        searchParams.questionName,
        searchParams.client,
        searchParams.useClient
      )
      .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setData(res.data.sort((a, b) => (a.enqueteId > b.enqueteId ? -1 : 1)));
        setCurrentPage(1);
      });
  };

  const onDownload = (enqueteId: number, enqueteName: string) => {
    enqueteDownloadApi
      .enqueteDownload(enqueteId)
      .then((res: AxiosResponse<string>) => {
        console.log('onDownload', res.data);
        execDownload(
          res.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          `${enqueteName}_社内用.xlsx`
        ).catch(() => {
          history.push(Url.COMMON_ERROR);
        });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  };

  const onQuestionCategoryDownload = () => {
    questionCategoryDownloadApi
      .questionCategoryForEachHierarchyListDownload()
      .then((res: AxiosResponse<string>) => {
        console.log('onDownload', res.data);
        execDownload(
          res.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          '選択式専用設問カテゴリ一覧.xlsx'
        ).catch(() => {
          history.push(Url.COMMON_ERROR);
        });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  };

  const onDrop = (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      enqueteApi
        .enqueteUpload({
          data: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '',
        })
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          const { result, ...rest } = res.data;
          console.log({ checkResult: result, ...rest });

          if (res.data.result) {
            setIsOkModal(true);
            setIsModal(false);
          } else {
            setMeg(res.data.errorMessage);
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    };
    reader.readAsDataURL(file);
  };

  // 【事前】
  // 【事後 積上式】
  // 【事後 その他】
  // 【会員属性】
  // 【アンケートモニター】タブの場合のPageContents
  const pageContents1 = (
    <>
      <>
        <Modal
          centered
          isModal={isOkModal}
          body={
            <div className="text-center">
              <p>アップロードしました</p>
              <Button onClick={() => history.go(0)}>OK</Button>
            </div>
          }
        />
        {msg && (
          <Modal
            centered
            isModal
            body={
              <div className="text-center">
                <p>エラー：{msg}</p>
                <Button onClick={() => setMeg(undefined)}>OK</Button>
              </div>
            }
          />
        )}
        <Modal
          centered
          isModal={isModal}
          closeButton
          onHide={() => setIsModal(false)}
          body={
            <>
              <Dropzone onDrop={onDrop} type="excel" />
            </>
          }
        />
        <div className="bg-light p-2 mb-4">
          <div className="d-flex" style={{ width: '100%', height: '100%' }}>
            <div className="form-floating me-2" style={{ width: '33%' }}>
              <input
                type="number"
                className="form-control"
                id="enqueteId"
                placeholder="入力してください。"
                onChange={onChangeText}
                value={searchParams.enqueteId === undefined ? '' : searchParams.enqueteId}
                data-testid="enqueteId"
              />
              <label htmlFor="enqueteId">アンケートID</label>
            </div>
            <div className="form-floating me-2" style={{ width: '33%' }}>
              <input
                type="text"
                className="form-control"
                id="enqueteName"
                placeholder="入力してください。"
                onChange={onChangeText}
                value={searchParams.enqueteName === undefined ? '' : searchParams.enqueteName}
                data-testid="enqueteName"
              />
              <label htmlFor="enqueteName">アンケート名</label>
            </div>
            <div className="form-floating" style={{ width: '34%' }}>
              <input
                type="text"
                className="form-control"
                id="useClient"
                placeholder="入力してください。"
                onChange={onChangeText}
                value={searchParams.useClient === undefined ? '' : searchParams.useClient}
                data-testid="useClient"
              />
              <label htmlFor="useClient">使用クライアント</label>
            </div>
          </div>
          <div className="d-flex mt-2" style={{ width: '100%', height: '100%' }}>
            <div className="form-floating me-2" style={{ width: '42%' }}>
              <input
                type="number"
                className="form-control"
                id="questionId"
                placeholder="入力してください。"
                onChange={onChangeText}
                value={searchParams.questionId === undefined ? '' : searchParams.questionId}
                data-testid="questionId"
              />
              <label htmlFor="questionId">設問ID</label>
            </div>
            <div className="form-floating me-2" style={{ width: '43%' }}>
              <input
                type="text"
                className="form-control"
                id="questionName"
                placeholder="入力してください。"
                onChange={onChangeText}
                value={searchParams.questionName === undefined ? '' : searchParams.questionName}
                data-testid="questionName"
              />
              <label htmlFor="questionName">設問</label>
            </div>
            <div style={{ textAlign: 'center', width: '15%' }}>
              <Button type="submit" style={{ width: '100%', height: '100%' }}>
                検索
              </Button>
            </div>
          </div>
        </div>
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(data.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
        <div className="mb-0" style={{ textAlign: 'right' }}>
          {when(
            enqueteType === ENQUETE_TYPE.PILEUP,
            <>
              <Button
                data-testid="add"
                variant="link"
                className="text-secondary p-0 ms-4"
                onClick={() => {
                  setIsModal(true);
                }}
              >
                <FontAwesomeIcon icon={faFileUpload} fixedWidth className="me-1" />
              </Button>
            </>
          )}
          {when(
            !salesFlg,
            <Button data-testid="add" variant="link" className="text-secondary p-0 ms-4">
              <Link to={`${Url.KEISAI.ENQUETE_MODIFY}/${enqueteType}`} target="_blank">
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                <span style={{ fontSize: '1rem' }}>追加</span>
              </Link>
            </Button>
          )}
        </div>
        <EnqueteListTable
          data={data}
          setData={setData}
          listPerPage={listPerPage}
          enqueteType={enqueteType}
          setDeleteResult={setDeleteResult}
          onDownload={onDownload}
          salesFlg={salesFlg}
        />
      </>
    </>
  );

  // 【事後 選択式】タブの場合のPageContents
  const pageContents2 = (
    <>
      <div className="mb-4 mt-4 bg-light">
        <div className="d-flex p-2" style={{ width: '100%', height: '100%' }}>
          <div className="form-floating me-2" style={{ width: '25%' }}>
            <input
              type="number"
              className="form-control"
              id="enqueteId"
              placeholder="入力してください。"
              onChange={onChangeText}
              value={searchParams.enqueteId === undefined ? '' : searchParams.enqueteId}
              data-testid="enqueteId"
            />
            <label htmlFor="enqueteId">アンケートID</label>
          </div>
          <div className="form-floating me-2" style={{ width: '25%' }}>
            <input
              type="text"
              className="form-control"
              id="enqueteName"
              placeholder="入力してください。"
              onChange={onChangeText}
              value={searchParams.enqueteName === undefined ? '' : searchParams.enqueteName}
              data-testid="enqueteName"
            />
            <label htmlFor="enqueteName">アンケート名</label>
          </div>
          <div className="form-floating me-2" style={{ width: '25%' }}>
            <input
              type="text"
              className="form-control"
              id="client"
              placeholder="入力してください。"
              onChange={onChangeText}
              value={searchParams.client === undefined ? '' : searchParams.client}
              data-testid="client"
            />
            <label htmlFor="client">管理クライアント</label>
          </div>
          <div className="form-floating" style={{ width: '25%' }}>
            <input
              type="text"
              className="form-control"
              id="useClient"
              placeholder="入力してください。"
              onChange={onChangeText}
              value={searchParams.useClient === undefined ? '' : searchParams.useClient}
              data-testid="useClient"
            />
            <label htmlFor="useClient">使用クライアント</label>
          </div>
        </div>

        <div className="d-flex mb-2" style={{ width: '100%', height: '85%', margin: 'auto' }}>
          <div className="form-floating ms-2 " style={{ width: '25%' }}>
            <input
              type="number"
              className="form-control"
              id="questionId"
              placeholder="入力してください。"
              onChange={onChangeText}
              value={searchParams.questionId === undefined ? '' : searchParams.questionId}
              data-testid="questionId"
            />
            <label htmlFor="questionId">設問ID</label>
          </div>
          <div className="form-floating ms-2 " style={{ width: '25%' }}>
            <input
              type="text"
              className="form-control"
              id="questionName"
              placeholder="入力してください。"
              onChange={onChangeText}
              value={searchParams.questionName === undefined ? '' : searchParams.questionName}
              data-testid="questionName"
            />
            <label htmlFor="questionName">設問</label>
          </div>
          <div className="form-floating me-2 ms-2" style={{ width: '25%' }}>
            <RecommendFloatingLabel
              data-testid="category"
              value={searchParams.categoryName !== undefined ? searchParams.categoryName : ''}
              label="業種"
              options={categoryList?.map((_) => _.name)}
              onChange={(e) => {
                setSearchParms({
                  ...searchParams,
                  categoryId: categoryList.find((row) => {
                    return row.name === e.target.value;
                  })?.id,
                  categoryName: e.target.value,
                });
              }}
              onDelete={() => {
                setSearchParms({ ...searchParams, categoryId: undefined, categoryName: undefined });
              }}
              onClickItem={(e) => {
                setSearchParms({
                  ...searchParams,
                  categoryId: categoryList.find((row) => {
                    return row.name === e.currentTarget.innerHTML;
                  })?.id,
                  categoryName: e.currentTarget.innerHTML,
                });
              }}
            />
          </div>
          <div className="form-floating me-2" style={{ width: '25%' }}>
            <RecommendFloatingLabel
              value={searchParams.businessCategoryName !== undefined ? searchParams.businessCategoryName : ''}
              label="提供業態"
              options={businessCategoryList
                ?.filter((row) => {
                  return row.categoryId === searchParams.categoryId;
                })
                .map((_) => _.name)}
              onChange={(e) => {
                setSearchParms({
                  ...searchParams,
                  businessCategoryId: businessCategoryList.find((row) => {
                    return row.name === e.target.value;
                  })?.id,
                  businessCategoryName: e.target.value,
                });
              }}
              onDelete={() => {
                setSearchParms({ ...searchParams, businessCategoryId: undefined, businessCategoryName: undefined });
              }}
              onClickItem={(e) => {
                setSearchParms({
                  ...searchParams,
                  businessCategoryId: businessCategoryList.find((row) => {
                    return row.name === e.currentTarget.innerHTML;
                  })?.id,
                  businessCategoryName: e.currentTarget.innerHTML,
                });
              }}
              disable={searchParams.categoryId === undefined}
            />
          </div>
        </div>
        <p style={{ width: '25%', height: '100%', margin: 'auto' }}>
          <Button type="submit" className="mb-2" style={{ width: '100%', height: '100%' }}>
            検索
          </Button>
        </p>
      </div>
      <PaginationWithEllipsis
        currentPage={currentPage}
        totalPage={totalPage}
        handleClick={(page) => {
          if (!page || page > totalPage) return;
          setListPerPage(data.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
          setCurrentPage(page);
        }}
      />
      <div className="mb-0" style={{ textAlign: 'right' }}>
        <OverlayTrigger placement="top-end" overlay={questionCategoryDownloadTooltip} rootClose>
          <Button
            data-testid="questionCategoryDownload"
            variant="link"
            className="text-secondary ms-4"
            onClick={() => {
              onQuestionCategoryDownload();
            }}
          >
            <FontAwesomeIcon icon={faFileDownload} fixedWidth className="me-1" />
          </Button>
        </OverlayTrigger>
        {when(
          !salesFlg,
          <Button data-testid="add" variant="link" className="text-secondary p-0 ms-4">
            <Link to={`${Url.KEISAI.ENQUETE_MODIFY}/${enqueteType}`} target="_blank">
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              <span style={{ fontSize: '1rem' }}>追加</span>
            </Link>
          </Button>
        )}
      </div>
      <EnqueteListTable
        data={data}
        setData={setData}
        listPerPage={listPerPage}
        enqueteType={enqueteType}
        setDeleteResult={setDeleteResult}
        onDownload={onDownload}
        salesFlg={salesFlg}
      />
    </>
  );

  return (
    <>
      <Title className="mb-4">アンケート一覧</Title>
      {deleteResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          削除しました。
        </Alert>
      )}
      {deleteResult !== null && deleteResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${deleteResult.errorMessage} (エラーコード：${deleteResult.errorCode})`}</Alert>
      )}
      <Card className="mb-4 p-4">
        <Form onSubmit={handleOnSubmit}>
          <Tabs
            defaultActiveKey={enqueteType}
            activeKey={enqueteType}
            id="uncontrolled-tab-example"
            onSelect={(key) => onChangeTopTab(key)}
            className="mb-4"
          >
            <Tab eventKey={ENQUETE_TYPE.BEFOREHAND} title="事前">
              {pageContents1}
            </Tab>
            <Tab eventKey={ENQUETE_TYPE.SELECT} title="事後 選択式">
              {pageContents2}
            </Tab>
            <Tab eventKey={ENQUETE_TYPE.PILEUP} title="事後 積上式">
              {pageContents1}
            </Tab>
            <Tab eventKey={ENQUETE_TYPE.OTHER} title="事後 その他">
              {pageContents1}
            </Tab>
            <Tab eventKey={ENQUETE_TYPE.MEMBERSHIP} title="会員属性">
              {pageContents1}
            </Tab>
            <Tab eventKey={ENQUETE_TYPE.ENQUETE_MONITOR} title="アンケートモニター">
              {pageContents1}
            </Tab>
          </Tabs>
        </Form>
      </Card>
    </>
  );
};
