import { AxiosResponse } from 'axios';
import Select, { MultiValue } from 'react-select';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
// eslint-disable-next-line import/no-cycle
import { CommonMasterListApi, ShopApi, ContractShopUpdateApi } from '../../../api-client';
import type {
  ShopUpdateOutputResponse,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
  ShopUpdateFormResponse,
  ContractShopUpdateFormResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';

interface Props {
  isModal: boolean;
  onHideShopListModal: () => void;
  onHide: () => void;
  contractShopId: number;
  mergeParam: (shopId: number, shopName: string, url: string) => void;
}

export type OptionType = { value: number; label: string };

export interface ModalState {
  isMessageModal: boolean;
  message: string;
  option: OptionType[];
}

export const ShopUpdateModal: React.FC<Props> = ({
  isModal,
  onHide,
  contractShopId,
  mergeParam,
  onHideShopListModal,
}) => {
  const initialUpdData: ShopUpdateFormResponse = {
    categoryId: 0,
    indexFlg: true,
    name: '',
    nameKana: '',
    genres1SelectedList: [],
    genres2SelectedList: [],
    prefecturesSelectedList: [],
  };

  const [updParam, setUpdParam] = useState<Partial<ShopUpdateFormResponse>>(initialUpdData);
  const [categories, setCategories] = useState<CommonMasterListOutputResponse[]>();
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();
  const [genres1, setGenres1] = useState<CommonMasterListOutputResponse[]>();
  const [genres2, setGenres2] = useState<CommonMasterListOutputResponse[]>();
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  // 保存ボタンフラグ
  const [keepFlg, setKeepFlg] = useState<boolean>(false);

  const commonMasterListApi = new CommonMasterListApi();
  const updateApi = new ShopApi();
  const contractShopUpdateApi = new ContractShopUpdateApi();

  const { state: $modal, mergeState } = useLargeState<ModalState>({
    isMessageModal: false,
    message: '',
    option: [],
  });

  // カテゴリ:美容
  const CATEGORY_BEAUTY_ID = 2;

  const [genre2DisabledFlg, setGenre2DisabledFlg] = useState<boolean>(false);

  useEffect(() => {
    Promise.all(
      [{ key: 'category' }, { key: 'prefecture' }, { key: 'genre', type: 1 }, { key: 'genre', type: 2 }].map(
        ({ key, type }): Promise<CommonMasterListOutputResponse[]> =>
          new Promise((resolve, reject) => {
            commonMasterListApi
              .commonMasterList(key, type)
              .then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => resolve(res.data))
              .catch((err: any) => reject(new Error(err)));
          })
      )
    ).then(([allCategories, allPrefectures, allGenres1, allGenres2]) => {
      setCategories(allCategories);
      setGenres1(allGenres1);
      setGenres2(allGenres2);
      setPrefectures(allPrefectures);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUpdResult(undefined);
    setKeepFlg(false);
  }, [updParam]);

  // テキストChangeイベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'shopName':
        setUpdParam({ ...updParam, name: event.target.value });
        break;
      case 'shopNameKana':
        setUpdParam({ ...updParam, nameKana: event.target.value });
        break;
      case 'introduction':
        setUpdParam({ ...updParam, introduction: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'shopURL':
        setUpdParam({ ...updParam, shopURL: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'phoneNumber':
        setUpdParam({
          ...updParam,
          phoneNumber: event.target.value ? event.target.value.replace(/[^0-9-]/g, '') : undefined,
        });
        break;
      case 'zipCode':
        setUpdParam({
          ...updParam,
          zipCode: event.target.value ? event.target.value.replace(/[^0-9-]/g, '') : undefined,
        });
        break;
      case 'address':
        setUpdParam({ ...updParam, address: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'latitude':
        setUpdParam({
          ...updParam,
          latitude: Number.isNaN(Number(event.target.value)) ? undefined : Number(event.target.value),
        });
        break;
      case 'longitude':
        setUpdParam({
          ...updParam,
          longitude: Number.isNaN(Number(event.target.value)) ? undefined : Number(event.target.value),
        });
        break;
      case 'access':
        setUpdParam({ ...updParam, access: event.target.value === '' ? undefined : event.target.value });
        break;
      default:
        break;
    }
  };

  // 保存ボタンイベント
  const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const genres1InputFlg =
      updParam.genres1SelectedList && updParam.genres1SelectedList.filter((value) => value.id !== 0).length !== 0;

    const nameInputFlg = updParam.name && updParam.name.length !== 0;
    const nameKanaInputFlg = updParam.nameKana && updParam.nameKana.length !== 0;
    const genres2InputFlg =
      updParam.genres2SelectedList && updParam.genres2SelectedList.filter((value) => value.id !== 0).length !== 0;
    const isNotLatitude = Boolean(!updParam.latitude && updParam.longitude);
    const isNotLongitude = Boolean(updParam.latitude && !updParam.longitude);

    const emptyFlgList = [!nameInputFlg, !nameKanaInputFlg, !updParam.categoryId];
    emptyFlgList.push(!genres2InputFlg && !genre2DisabledFlg);
    if (updParam.categoryId === CATEGORY_BEAUTY_ID && !genres1InputFlg) {
      emptyFlgList.push(true);
    } else {
      emptyFlgList.push(false);
    }

    if (isNotLatitude) emptyFlgList.push(isNotLatitude);
    if (isNotLongitude) emptyFlgList.push(isNotLongitude);

    if (emptyFlgList.some((flg) => flg)) {
      const labelList = ['実店舗名', '実店舗名ふりがな', 'カテゴリ'];
      labelList.push('ジャンル2');
      labelList.push('ジャンル1');
      if (isNotLatitude) labelList.push('緯度');
      if (isNotLongitude) labelList.push('経度');
      const emptylabelList: string[] = [];
      emptyFlgList.forEach((flg, i) => {
        if (flg) {
          emptylabelList.push(labelList[i]);
        }
      });
      mergeState({
        message: `${emptylabelList.join('・')}が入力されていません`,
        isMessageModal: true,
      });
      return;
    }

    await updateApi
      .shopUpdate({
        ...updParam,
        phoneNumber: updParam.phoneNumber ? updParam.phoneNumber : undefined,
        zipCode: updParam.zipCode ? updParam.zipCode.replace('-', '') : undefined,
      } as ShopUpdateFormResponse)
      .then((res: AxiosResponse<ShopUpdateOutputResponse>) => {
        setUpdResult(res.data);
        const shopId = res.data.id;

        if (shopId) {
          const form: ContractShopUpdateFormResponse = { id: contractShopId, shopId };

          contractShopUpdateApi.contractShopUpdate(form).then((response: AxiosResponse<IncResultOutputResponse>) => {
            setUpdResult(response.data);
          });

          mergeParam(shopId, `${updParam.name}`, `${updParam.shopURL}`);
          onHide();
          onHideShopListModal();
        }
      });
  };

  return (
    <>
      <Modal
        centered
        isModal={$modal.isMessageModal}
        body={
          <>
            <p className="text-center">{$modal.message}</p>
            <div className="text-center">
              <Button
                onClick={() => {
                  mergeState({ isMessageModal: false });
                }}
              >
                OK
              </Button>
            </div>
          </>
        }
      />
      <Modal
        closeButton
        centered
        scrollable
        size="lg"
        isModal={isModal}
        onHide={onHide}
        body={
          <>
            <Form onSubmit={handleOnSubmit}>
              <h3 style={{ marginBottom: '20px' }}>実店舗作成</h3>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {keepFlg && updResult?.result && (
                  <Alert variant="success" style={{ marginBottom: '24px' }}>
                    保存が完了しました。
                  </Alert>
                )}
                {keepFlg && updResult !== undefined && updResult?.result === false && (
                  <Alert
                    variant="danger"
                    style={{ marginTop: 10 }}
                  >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
                )}
                <Card style={{ marginTop: '24px' }}>
                  <Card.Header>
                    <h4 style={{ margin: 'auto' }}>実店舗情報</h4>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group className="mb-4" controlId="shopName">
                      <Form.Label className="fw-bold">
                        実店舗名<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" onChange={onChangeText} onInput={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="shopNameKana">
                      <Form.Label className="fw-bold">
                        実店舗名ふりがな<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control type="text" onChange={onChangeText} onInput={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="introduction">
                      <Form.Label className="fw-bold">紹介文</Form.Label>
                      <Form.Control as="textarea" onChange={onChangeText} rows={5} onInput={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="shopURL">
                      <Form.Label className="fw-bold">実店舗URL</Form.Label>
                      <Form.Control type="text" onChange={onChangeText} onInput={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="introduction">
                      <Form.Label className="fw-bold">複数都道府県設定</Form.Label>
                      <Select
                        isMulti
                        id="prefecture"
                        onChange={(multiVal) => {
                          setUpdParam({ ...updParam, prefecturesSelectedList: multiVal.map((val) => val.id) });
                        }}
                        closeMenuOnSelect={false}
                        value={updParam.prefecturesSelectedList?.map((id) => ({
                          id,
                          name: prefectures?.filter(({ id: pid }) => pid === id)[0].name,
                        }))}
                        getOptionLabel={({ name }) => name || ''}
                        getOptionValue={({ id: optId }) => String(optId)}
                        options={prefectures}
                        placeholder="選択してください。"
                      />
                    </Form.Group>

                    <Form.Group className="mb-4">
                      <Form.Label className="fw-bold">
                        カテゴリ<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        value={updParam.categoryId || ''}
                        onChange={(e) => {
                          setUpdParam((prev) => ({
                            ...prev,
                            categoryId: Number(e.target.value),
                            genres1SelectedList: [],
                            genres2SelectedList: [],
                          }));
                          setGenre2DisabledFlg(e.target.value === CATEGORY_BEAUTY_ID.toString());
                        }}
                      >
                        <option value="">選択してください</option>
                        {categories?.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    {(['genres1SelectedList', 'genres2SelectedList'] as const).map((name, i) => (
                      <div className="mb-4" key={name}>
                        <Form.Label className="fw-bold">
                          {`ジャンル${i + 1}`}
                          {(() => {
                            if (updParam.categoryId === CATEGORY_BEAUTY_ID && i === 0) {
                              return <span className="text-danger">*</span>;
                            }
                            if (updParam.categoryId !== CATEGORY_BEAUTY_ID && i === 1) {
                              return <span className="text-danger">*</span>;
                            }
                          })()}
                        </Form.Label>
                        <div className="bg-light p-3">
                          <Form.Group className="mb-4">
                            <Form.Label className="fw-bold">メイン</Form.Label>
                            <Form.Select
                              value={updParam[name]?.find(({ mainFlg }) => mainFlg)?.id || ''}
                              disabled={i === 1 && genre2DisabledFlg}
                              onChange={(e) => {
                                setUpdParam({
                                  ...updParam,
                                  [name]: [
                                    ...(updParam[name]?.filter((g) => !g.mainFlg) || []),
                                    { id: Number(e.target.value), mainFlg: true },
                                  ],
                                });
                              }}
                            >
                              <option value="">&nbsp;</option>
                              {(i === 0 ? genres1 : genres2)
                                ?.filter((g) => g.categoryId === updParam.categoryId)
                                ?.filter(
                                  (g) =>
                                    !updParam[name]
                                      ?.filter(({ mainFlg }) => !mainFlg)
                                      ?.map((s) => s.id)
                                      .includes(g.id)
                                )
                                ?.map((g) => (
                                  <option key={g.id} value={g.id}>
                                    {g.name}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>

                          <Form.Group>
                            <Form.Label className="fw-bold">サブ</Form.Label>
                            <Select
                              isMulti
                              id={`subGenre${i + 1}`}
                              isDisabled={i === 1 && genre2DisabledFlg}
                              onChange={(val: MultiValue<CommonMasterListOutputResponse>) => {
                                setUpdParam({
                                  ...updParam,
                                  [name]: [
                                    ...(updParam[name]?.filter((g) => g.mainFlg) || []),
                                    ...val.map((g) => ({ id: g.id, mainFlg: false })),
                                  ],
                                });
                              }}
                              value={(i === 0 ? genres1 : genres2)
                                ?.filter((g) => g.id !== updParam[name]?.find((s) => s.mainFlg)?.id)
                                ?.filter((g) => updParam[name]?.map((s) => s.id).includes(g.id))}
                              getOptionLabel={({ name: oName }) => oName}
                              getOptionValue={({ id: oId }) => String(oId)}
                              options={(i === 0 ? genres1 : genres2)
                                ?.filter((g) => g.categoryId === updParam.categoryId)
                                ?.filter((g) => !updParam[name]?.map((s) => s.id).includes(g.id))}
                              closeMenuOnSelect={false}
                              placeholder=""
                            />
                          </Form.Group>
                        </div>
                      </div>
                    ))}

                    <Form.Group className="mb-4 mt-4" controlId="phoneNumber">
                      <Form.Label className="fw-bold">実店舗電話番号</Form.Label>
                      <Form.Control type="string" value={updParam.phoneNumber || ''} onChange={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="zipCode">
                      <Form.Label className="fw-bold">実店舗郵便番号</Form.Label>
                      <Form.Control type="string" value={updParam.zipCode || ''} onChange={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="address">
                      <Form.Label className="fw-bold">実店舗住所</Form.Label>
                      <Form.Control type="text" onChange={onChangeText} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="latitude">
                      <Form.Label className="fw-bold">緯度</Form.Label>
                      <Form.Control type="number" onChange={onChangeText} step={0.00000000000001} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="longitude">
                      <Form.Label className="fw-bold">経度</Form.Label>
                      <Form.Control type="number" onChange={onChangeText} step={0.00000000000001} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="access">
                      <Form.Label className="fw-bold">アクセス</Form.Label>
                      <Form.Control type="text" onChange={onChangeText} />
                    </Form.Group>
                    <Form.Group controlId="indexFlg" className="d-flex justify-content-between">
                      <Form.Label className="fw-bold">Google/Yahoo検索表示フラグ</Form.Label>
                      <Form.Check
                        type="switch"
                        id="indexFlg"
                        checked={updParam.indexFlg ?? true}
                        onChange={() => {
                          setUpdParam((prev) => ({ ...prev, indexFlg: !prev.indexFlg }));
                        }}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
                <Button id="keep" type="submit" style={{ textAlign: 'center' }}>
                  作成して紐づけ
                </Button>
              </div>
            </Form>
          </>
        }
      />
    </>
  );
};
