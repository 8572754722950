import { Table } from 'react-bootstrap';
import React from 'react';
import scss from '../../scss/organisms/scrollTable.module.scss';
import {
  AnswerHistoryDtoResponse,
  AnswerNgHistoryListOutputResponse,
  CorrectionNgCommentForAnswerDtoResponse,
  ImageDtoResponse,
} from '../../api-client';
import { ANSWER_METHOD } from '../organisms/Modal/Question/QuestionConfirmModal';

interface Props {
  data?: AnswerNgHistoryListOutputResponse;
}

export const AnswerHistoryListTable: React.FC<Props> = ({ data }) => {
  const headers = data?.headers ?? [];
  const questionAnswerList = data?.questionAnswers ?? [];
  return (
    <div style={{ overflow: 'auto', width: '100vw', height: '100vh' }}>
      <Table bordered>
        <thead>
          <tr style={{ position: 'sticky', top: 0, zIndex: 10 }}>
            <th
              style={{ minWidth: '300px', maxWidth: '300px', backgroundColor: '#bdbdbc' }}
            >{`応募ID:${data?.applyId} 実店舗ID:${data?.shopId} 実店舗名:${data?.shopName}`}</th>
            {headers.map((v) => {
              return (
                <>
                  <th
                    colSpan={3}
                    style={{
                      minWidth: '900px',
                      maxWidth: '900px',
                      backgroundColor: `${v.correctionPhase % 2 === 0 ? '#f5a4a4' : '#a4b5f5'}`,
                    }}
                  >
                    {v.title}
                  </th>
                </>
              );
            })}
          </tr>
          <tr style={{ position: 'sticky', top: 42, zIndex: 10 }}>
            <th style={{ backgroundColor: '#bdbdbc' }}>設問</th>
            {headers.map((v) => {
              return (
                <>
                  <th
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px',
                      backgroundColor: `${v.correctionPhase % 2 === 0 ? '#f5a4a4' : '#a4b5f5'}`,
                    }}
                  >
                    {v.userAnswerName}
                  </th>
                  <th
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px',
                      backgroundColor: `${v.correctionPhase % 2 === 0 ? '#f5a4a4' : '#a4b5f5'}`,
                    }}
                  >
                    {v.userAnswerQcModifiedName}
                  </th>
                  <th
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px',
                      backgroundColor: `${v.correctionPhase % 2 === 0 ? '#f5a4a4' : '#a4b5f5'}`,
                    }}
                  >
                    {v.correctionNgString}
                  </th>
                </>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {questionAnswerList.map((v, index) => {
            return (
              <tr>
                <td
                  style={{ backgroundColor: `${index % 2 === 0 ? '#fff5d9' : '#fff0c9'}`, position: 'sticky', left: 0 }}
                >
                  {`${index + 1}.${v.question}`}
                </td>
                {headers.map((header) => {
                  return (
                    <>
                      <AnswerTd
                        questionAnswers={v.customerAnswer}
                        correctionPhase={header.correctionPhase}
                        questionIndex={index}
                      />
                      <AnswerTd
                        questionAnswers={v.customerAnswerQcModified}
                        correctionPhase={header.correctionPhase}
                        color="blue"
                        questionIndex={index}
                      />
                      <NgTd
                        ngComments={v.ng}
                        correctionPhase={header.correctionPhase}
                        color="red"
                        questionIndex={index}
                      />
                    </>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const AnswerTd = ({
  questionAnswers,
  correctionPhase,
  color,
  questionIndex,
}: {
  questionAnswers: AnswerHistoryDtoResponse[];
  correctionPhase: number;
  color?: string;
  questionIndex: number;
}) => {
  const phaseAnswer = questionAnswers.find((v) => v.correctionPhase === correctionPhase);
  const getBackgroundColor = () => {
    if (correctionPhase % 2 === 0) {
      if (questionIndex % 2 === 0) {
        return '#fbe3e3';
      }
      return '#ffd3d3';
    }
    if (questionIndex % 2 === 1) {
      return '#FFFFFF';
    }
    return '#d7d7d7';
  };
  if (phaseAnswer?.answerMethodCode === ANSWER_METHOD.IMAGE) {
    return (
      <td style={{ backgroundColor: getBackgroundColor(), maxWidth: '300px', minWidth: '300px' }}>
        <AnswerImages images={phaseAnswer.answerImages} />
      </td>
    );
  }
  return (
    <td style={{ backgroundColor: getBackgroundColor(), color: color, maxWidth: '300px', minWidth: '300px' }}>
      {getAnswer(questionAnswers, correctionPhase)}
    </td>
  );
};

const AnswerImages = ({ images }: { images?: ImageDtoResponse[] }) => {
  return (
    <>
      {images?.map((v) => (
        <img src={v.path1} alt="" width="300px" />
      ))}
    </>
  );
};

const NgTd = ({
  ngComments,
  correctionPhase,
  color,
  questionIndex,
}: {
  ngComments: CorrectionNgCommentForAnswerDtoResponse[];
  correctionPhase: number;
  color?: string;
  questionIndex: number;
}) => {
  const getBackgroundColor = () => {
    if (correctionPhase % 2 === 0) {
      if (questionIndex % 2 === 0) {
        return '#fbe3e3';
      }
      return '#ffd3d3';
    }
    if (questionIndex % 2 === 1) {
      return '#FFFFFF';
    }
    return '#d7d7d7';
  };
  return (
    <td style={{ backgroundColor: getBackgroundColor(), color: color, maxWidth: '300px', minWidth: '300px' }}>
      {getNgComment(ngComments, correctionPhase)}
    </td>
  );
};
const getAnswer = (questionAnswers: AnswerHistoryDtoResponse[], correctionPhase: number) => {
  let answer = '-';
  const phaseAnswer = questionAnswers.find((v) => v.correctionPhase === correctionPhase);
  if (phaseAnswer === undefined) return answer;

  if (phaseAnswer?.selections !== undefined && phaseAnswer.selections.length > 0) {
    answer = '';
    phaseAnswer.selections.map((v) => {
      answer += `${v.content}\n`;
    });
    return answer;
  }
  return phaseAnswer?.freeAnswer ?? answer;
};

const getNgComment = (ngComments: CorrectionNgCommentForAnswerDtoResponse[], correctionPhase: number) => {
  const ngComent = ngComments.find((v) => v.correctionPhase === correctionPhase);
  return ngComent?.ngMessage ?? '-';
};

// const createAnswerHistoryContent = (answer: AnswerForAnswerNgHistoryListResponse) => {
//   answer.imageUrls.map((url: string) => {
//     return <td>
//       {/* eslint-disable-next-line jsx-a11y/alt-text */}
//       <img src={url} width='300ox' />
//     </td>
//   })
//   return <td>{answer.contents}</td>;
// };

enum AnswerHistoryHeaderType {
  QUESTION,
  CUSTOMERS_ANSWER,
  INCS_ANSWER,
  NG,
}

const HEADER_ARRAY = new Map<AnswerHistoryHeaderType, string>([
  [AnswerHistoryHeaderType.QUESTION, `bg-dark text-white ${scss.table_stick_question_th}`],
  [AnswerHistoryHeaderType.CUSTOMERS_ANSWER, `bg-success text-white ${scss.table_sticky_th}`],
  [AnswerHistoryHeaderType.INCS_ANSWER, `bg-secondary text-white ${scss.table_sticky_th}`],
  [AnswerHistoryHeaderType.NG, `bg-warning text-white ${scss.table_sticky_th}`],
]);
